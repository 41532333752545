import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import IssuanceLayout from "layouts/IssuanceLayout";
import ParallelSection from "components/issuance/parallelSections";
import {
  Autocomplete,
  Stack,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Grid,
  Box
} from "@mui/material";

import Editor from "components/common/Editor";
import DatePicker from "components/common/DatePicker";
import { customAxios } from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "components/common/CustomDialog";
import CreateLabelForm from "components/common/CreateLabelForm";
import { useIssuanceDataProvider } from "hooks/useIssuanceDataProvider";
import SkillsInput from "components/common/SkillsInput";
import moment from "moment";
import MySwal from "utils/Swal";
import UploadField from "components/common/UploadField";

export default function Settings() {
  const { issuanceData, setIssuanceData } = useIssuanceDataProvider();
  const { issuanceType } = useParams();
  const { user, reloadUser } = useAuth();

  console.log("user_departments", user);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [isSaving, setIsSaving] = React.useState(false);
  const [formData, setFormData] = React.useState({
    ...(issuanceData?.credentialDetails || {}),
    imageData: {
      ...((issuanceData?.template?.placeholders?.imagePlaceholders || []).filter(key => key !== "ICQR").reduce((acc, key) => (acc[key] = null, acc), {})),
      ...(issuanceData?.credentialDetails?.imageData || {})
    }
  });

  console.log("form_Data", formData)

  const [drawer, setDrawer] = React.useState(false);

  const handleNext = async (e) => {
    e.preventDefault();
    if (
      !formData?.notificationPlatform?.email &&
      !formData?.notificationPlatform?.whatsapp
    ) {
      MySwal.fire({
        title: "Please select atleast one delivery method!",
        icon: "error",
      });
      return;
    }
    setIsSaving(true);
    const response = await customAxios.post(
      "profile/organisation/settings",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    if (response) {
      reloadUser();
      setIssuanceData(
        { credentialDetails: { ...formData, isCompleted: true } },
        () =>
          navigate(
            `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/recipients/${location.search}`,
          ),
      );
    }
    setIsSaving(false);
  };

  // Save on unmount
  const oldPathName = location.pathname;
  React.useEffect(() => {
    return () => {
      if (oldPathName !== window.location.pathname) {
        setIssuanceData({ credentialDetails: formData });
      }
    };
  }, [formData]);

  const handleSave = (e) => {
    e.target.innerText = "Saving...";
    const callback = () => {
      e.target.innerText = "Saved!";
      setTimeout(() => {
        e.target.innerText = "Save";
      }, 5000);
    };
    setIssuanceData({ credentialDetails: formData }, callback);
  };

  const uploadImagePlaceholder = async (file, key) => {
    file = file && await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
    setFormData(prevState => ({ ...prevState, imageData: { ...prevState.imageData, [key]: file } }));
  }

  return (
    <IssuanceLayout>
      <ParallelSection
        data={{ credentialDetails: formData }}
        drawer={drawer}
        setDrawer={setDrawer}
      >
        <form onSubmit={handleNext} data-tut="CredentialSetting">
          <Stack spacing={4}>
            <Typography variant="h4">Credential Settings</Typography>

            <TextField
              required
              label="Credential Title"
              size="small"
              value={formData?.title}
              InputLabelProps={{ shrink: Boolean(formData?.title) }}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }))
              }
            />

            {Boolean(Object.keys(formData?.imageData).length) && (
              <Box>
                <Grid container spacing={2}>
                  {Object.entries(formData.imageData).map(([label, value]) => (
                    <Grid item xs={Object.keys(formData.imageData).length > 1 ? 6 : 12} key={label}>
                      <UploadField label={label} value={value} onChange={(newValue) => uploadImagePlaceholder(newValue, label)} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <DatePicker
              label={"Expiry Date"}
              minDate="TODAY"
              value={formData.expiryDate}
              onChange={(newValue) =>
                setFormData((prevState) => ({
                  ...prevState,
                  expiryDate: moment(newValue).isValid() ? newValue : null,
                }))
              }
            />

            <Editor
              label="Document Description"
              helperText="Additional information you want to provide to about the credential holder"
              value={formData.docInfo}
              onChange={(newValue) =>
                setFormData((prevState) => ({
                  ...prevState,
                  docInfo: newValue,
                }))
              }
            />

            <Autocomplete
              multiple
              popupIcon={false}
              disableClearable
              forcePopupIcon={false}
              options={user?.tags}
              getOptionLabel={(option) => option}
              value={formData.tags}
              onChange={(e, value) =>
                setFormData((prevState) => ({ ...prevState, tags: value }))
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Labels"
                  helperText="Easily categorize and organize documents in labels for intuitive navigation"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <CustomDialog
                        AnchorComponent={(anchorProps) => (
                          <IconButton size="small" {...anchorProps}>
                            <AddIcon color="primary" />
                          </IconButton>
                        )}
                        title="Create New Label"
                        Content={(dialogProps) => (
                          <CreateLabelForm
                            {...dialogProps}
                            cb={(newLabel) => {
                              setFormData((prevState) => ({
                                ...prevState,
                                tags: Array.from(
                                  new Set([...formData.tags, newLabel]),
                                ),
                              }));
                            }}
                          />
                        )}
                      />
                    ),
                  }}
                />
              )}
            />

            {user?.departments?.length > 0 && <Autocomplete
              multiple
              popupIcon={false}
              forcePopupIcon={false}
              disabled={user?.departments?.length === 1 && user?.role !== "VM_COMPANY_ADMIN"}
              options={user?.departments}
              getOptionLabel={(option) => option.departmentName}
              value={formData.departments}
              onChange={(e, value) => {
                setFormData((prevState) => ({ ...prevState, departments: value }));
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Departments"
                  helperText="Select in which department you want to issue this certificate."
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />}


            <SkillsInput
              textFieldProps={{
                helperText:
                  "Add recipient-acquired skills directly onto certificates for personalized recognition.",
              }}
              defaultValue={formData.skills}
              onChange={(e, value) =>
                setFormData((prevState) => ({ ...prevState, skills: value }))
              }
            />

            <FormControl>
              <FormLabel sx={{ fontWeight: 500, color: "inherit" }}>
                Delivery Method
              </FormLabel>
              <Stack direction="row" spacing={4}>
                <FormControlLabel
                  label="Email"
                  control={
                    <Checkbox
                      checked={formData?.notificationPlatform?.email}
                      onChange={(e) =>
                        setFormData((prevstate) => ({
                          ...prevstate,
                          notificationPlatform: {
                            ...prevstate?.notificationPlatform,
                            email: e.target.checked,
                          },
                        }))
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="WhatsApp"
                  control={
                    <Checkbox
                      checked={formData?.notificationPlatform?.whatsapp}
                      onChange={(e) =>
                        setFormData((prevstate) => ({
                          ...prevstate,
                          notificationPlatform: {
                            ...prevstate?.notificationPlatform,
                            whatsapp: e.target.checked,
                          },
                        }))
                      }
                    />
                  }
                />
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel sx={{ fontWeight: 500, color: "inherit" }}>
                Private Credential
              </FormLabel>
              <FormHelperText sx={{ marginLeft: 0 }}>
                Private credentials will be only visible to you and the
                recipient
              </FormHelperText>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isPrivateDoc}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        isPrivateDoc: e.target.checked,
                      })
                    }
                  />
                }
                label="Record credentials as Private"
              />
            </FormControl>

            <Stack direction="row" spacing={2} justifyContent="center">
              {isMobile && (
                <Button
                  variant="outlined"
                  disableElevation
                  sx={{ minWidth: 100 }}
                  onClick={() => setDrawer(true)}
                >
                  Preview
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={handleSave}
                sx={{ minWidth: 100 }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                disableElevation
                sx={{ minWidth: 100 }}
                disabled={isSaving}
                type="submit"
                data-tut="CredentialSettingNextBtn"
              >
                {isSaving ? "Saving..." : "Next"}
              </Button>
            </Stack>
          </Stack>
        </form>
      </ParallelSection>
    </IssuanceLayout>
  );
}
