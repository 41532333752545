import React from "react";
import { Box, Skeleton } from "@mui/material";
import _ from "lodash";
import Thumbnail from "components/common/Thumbnail";
import { useAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";

const TemplateList = (props) => {
  const { templateType, fetchTemplateHtml } = props;
  const [data, isLoading] = useAxios(`templates?category=${templateType}`);
  const templates =
    data?.response?.certificateTemplates.filter(
      (templ) => templ.templateType === "HTML",
    ) || [];

  return isLoading
    ? _.times(12, (i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width="calc(50% - 6px)"
          height={100}
        />
      ))
    : templates.map((template, idx) => (
        <Thumbnail
          key={`${template.title}-${idx}`}
          url={template?.thumb?.large?.replace("cdn-bucket-mumbai.s3.ap-south-1.amazonaws.com", "cdn.itscredible.com")}
          width="calc(50% - 6px)"
          aspectRatio={1.2}
          onClick={() => {
            const element = document.getElementById("template-container");
            element.querySelector(".moveable-control-box")?.remove();
            if (element?.innerHTML) {
              MySwal.fire({
                title: "Are you sure?",
                text: "All your current changes will be lost?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                  fetchTemplateHtml(template._id);
                }
              });
            } else {
              fetchTemplateHtml(template._id);
            }
          }}
        />
      ));
};

const TemplateLibrary = (props) => {
  const { templateType, fetchTemplateHtml } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={1.5}
    >
      <TemplateList
        templateType={templateType}
        fetchTemplateHtml={fetchTemplateHtml}
      />
    </Box>
  );
};

export default TemplateLibrary;
