import React from "react";
import { Box } from "@mui/material";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import { customAxios } from "hooks/useAxios";
import validator from "validator";
import AddEmailForm from "./AddEmailForm";
import VerifyOtp from "./VerifyOtp";
import MySwal from "utils/Swal";

export default function MergeEmailPopup({ onVerification, warningText }) {
  const { user } = useCredulockerAuth();
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [step, setStep] = React.useState(1);
  const [isSending, setIsSending] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);

  const generateOtp = async (e, resend = false) => {
    e.preventDefault();

    if (!validator.isEmail(email) && !validator.isMobilePhone(email)) {
      MySwal.fire({
        text: "Invalid Email Address / WhatsApp Number",
        icon: "error",
      });
      return;
    }

    if (user.email.includes(email)) {
      MySwal.fire({
        text: "This email already exists in your account",
        icon: "error",
      });
      return;
    }

    let result;
    if (!resend && warningText) {
      result = await MySwal.fire({
        text: warningText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Merge",
      });
    }
    if (resend || result?.isConfirmed || !warningText) {
      setIsSending(true);
      const isCredulocker = window.location.href.includes("credulocker");
      const src = isCredulocker ? "credulocker" : "external";
      const response = await customAxios.post(
        "otp",
        {
          loginId: email,
          config: {
            sendVia: [
              {
                deliveryMethod: validator.isMobilePhone(email)
                  ? "whatsapp"
                  : "email",
                deliveryAddress: email,
              },
            ],
            src,
          },
        },
        { attachCredulockerToken: true },
      );
      if (response?.status === 200) {
        setStep(2);
      }
      setIsSending(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    await onVerification({email, otp});
    setIsVerifying(false);
  };

  const getForm = () => {
    if (step === 1) {
      return (
        <AddEmailForm
          {...{ email, setEmail, handleSubmit: generateOtp, isSending }}
        />
      );
    } else {
      return (
        <VerifyOtp
          {...{
            email,
            otp,
            setOtp,
            handleBack: () => setStep(1),
            handleResend: (e) => generateOtp(e, true),
            handleSubmit: verifyOtp,
            isSending,
            isVerifying,
          }}
        />
      );
    }
  };
  return (
    <Box sx={{maxWidth: '500px', margin: 'auto'}}>
      {getForm()}
    </Box>
  );
}
