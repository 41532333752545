import React, { useState } from "react";
import Header from "components/Header";
import {
  Box,
  Button,
  Fab,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import CustomTabs from "components/common/customTabs";
import { useNavigate } from "react-router-dom";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import OutboxOutlinedIcon from "@mui/icons-material/OutboxOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import { useAuth } from "hooks/useAuth";
import LabelIcon from "@mui/icons-material/LabelOutlined";
import CustomDialog from "components/common/CustomDialog";
import CreateLabelForm from "components/common/CreateLabelForm";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomPopover from "components/common/customPopover";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MySwal from "utils/Swal";
import { customAxios } from "hooks/useAxios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTour } from "hooks/useTour";
import { useTheme } from "@mui/material/styles";

export default function TabLayout(props) {
  const { user, reloadUser } = useAuth();
  const { setTourStepIdx } = useTour();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [drawer, setDrawer] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const tags = Array.from(new Set(user?.tags || []));

  const deleteLabel = async (tag) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete the label?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        MySwal.showLoader("Deleting in progress...");
        const response = await customAxios.post(
          "profile/removeTagFromProfile",
          { tag },
        );
        if (response?.status === 200) {
          await reloadUser();
          MySwal.fire({
            title: "Document(s) Deleted!",
            text: "The selected Document(s) has been deleted!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const filters = [
    {
      id: "SENT",
      "data-tut": "SentTab",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <VerifiedUserOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Sent</Typography>
        </Stack>
      ),
    },
    {
      id: "DRAFT",
      "data-tut": "DraftTab",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <InsertDriveFileOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Draft</Typography>
        </Stack>
      ),
    },
    {
      id: "SCHEDULED",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <TimerOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Scheduled</Typography>
        </Stack>
      ),
    },
    {
      id: "OUTBOX",
      "data-tut": "OutboxTab",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <OutboxOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Outbox</Typography>
        </Stack>
      ),
    },
    {
      id: "RECIPIENTS",
      "data-tut": "RecipientsTab",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <PeopleOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Recipients History</Typography>
        </Stack>
      ),
    },
    {
      id: "ANALYTICS",
      "data-tut": "AnalyticsTab",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <AnalyticsOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">Analytics</Typography>
        </Stack>
      ),
    },
    {
      id: "LABELS_HEAD_DISABLED",
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Label</Typography>
          <CustomDialog
            AnchorComponent={(anchorProps) => (
              <IconButton
                size="small"
                sx={{ pointerEvents: "auto" }}
                {...anchorProps}
              >
                <AddIcon color="primary" />
              </IconButton>
            )}
            title="Create New Label"
            Content={(dialogProps) => <CreateLabelForm {...dialogProps} />}
          />
        </Box>
      ),
      disabled: true,
    },
    ...tags.map((tag) => ({
      id: tag,
      path: `${process.env.REACT_APP_BASE_ROUTE}/user/home/`,
      label: (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            "&:hover button": {
              opacity: 1,
            },
          }}
          my={-0.5}
        >
          <Stack direction="row" spacing={1} alignItems="center" flex={1}>
            <LabelIcon fontSize="small" />
            <Typography variant="subtitle2" align="left">
              {tag}
            </Typography>
          </Stack>
          <CustomPopover
            AnchorComponent={(syntax) => (
              <IconButton size="small" sx={{ opacity: 0 }} {...syntax}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )}
            PopoverComponent={(syntax) => (
              <MenuList {...syntax}>
                <CustomDialog
                  AnchorComponent={(anchorProps) => (
                    <MenuItem {...anchorProps}>
                      <ListItemIcon>
                        <EditOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                  )}
                  title="Edit Label"
                  Content={(dialogProps) => (
                    <CreateLabelForm
                      labelName={tag}
                      cb={syntax.handleClose}
                      {...dialogProps}
                    />
                  )}
                />
                <MenuItem
                  onClick={(e) => {
                    e?.stopPropagation();
                    syntax?.handleClose();
                    deleteLabel(tag);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </MenuList>
            )}
          />
        </Box>
      ),
    })),
  ];

  const startExistingFlow = async () => {
    const { data } = await customAxios.post("draft", { draft: {} });
    navigate(
      `${process.env.REACT_APP_BASE_ROUTE}/issuance/existing/uploadCredentials/?draftId=${data._id}`,
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100dvh" }}>
      <Header setDrawer={setDrawer} />
      <Box
        p={0}
        sx={{
          flexGrow: 1,
          backgroundColor: isDarkMode ? "#444" : "#f4f2f0",
          height: "calc(100dvh - 55px)",
        }}
      >
        <CustomTabs
          variant={theme.palette.mode || "light"}
          filtersWidth="210px"
          cta={
            isMobile ? (
              <Fab
                variant="extended"
                color="primary"
                data-tut="NewCredentialBtn"
                onClick={() => {
                  setTourStepIdx((prevState) => prevState + 1);
                  navigate(
                    `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/selectTemplate`,
                  );
                }}
              >
                <AddIcon /> New
              </Fab>
            ) : (
              <Button
                variant="contained"
                fullWidth
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disableElevation
                data-tut="NewCredentialBtn"
                onClick={() => {
                  setTourStepIdx((prevState) => prevState + 1);
                  navigate(
                    `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/selectTemplate`,
                  );
                }}
              >
                <Typography variant="h6">New</Typography>
              </Button>
            )
          }
          bottomCta={
            <>
              <Button
                sx={isMobile ? { mx: 1.5, my: 1 } : { ml: 1.5, mb: 1 }}
                startIcon={<AssignmentTurnedInOutlinedIcon />}
                disableElevation
                onClick={startExistingFlow}
              >
                Existing Credentials
              </Button>
            </>
          }
          filters={filters}
          drawer={drawer}
          setDrawer={setDrawer}
        >
          {props.children}
        </CustomTabs>
      </Box>
    </Box>
  );
}
