import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Checkbox,
  TablePagination,
  Grid,
  TextField,
  Select,
  MenuItem,
  Popover,
  InputAdornment,
  Divider,
  Badge,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
} from "@tanstack/react-table";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import NoDataScreen from "components/CredentialsTable/NoDataScreen";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { useTheme } from '@mui/material/styles';

function IndeterminateCheckbox({ ...props }) {
  return <Checkbox {...props} sx={{ m: -1.5 }} />;
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <TextField
      {...props}
      size="small"
      className="extra-small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}

function Filter({ column }) {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  const sortedUniqueValues = React.useMemo(() => {
    const facets = column?.getFacetedUniqueValues();
    const uniqueValues = new Set();
    for (const [key] of facets) {
      if (Array.isArray(key)) {
        for (const k of key) {
          uniqueValues.add(k);
        }
      } else {
        uniqueValues.add(key);
      }
    }
    return Array.from(uniqueValues);
  }, [column.getFacetedUniqueValues(), filterVariant]);

  return filterVariant === "date-range" ? (
    <Stack direction="row" spacing={1}>
      <input
        value={columnFilterValue?.[0] || ""}
        type="date"
        style={{
          outline: "none",
          padding: "3px 0",
          fontFamily: "Poppins,sans-serif",
          width: 120
        }}
        onChange={(e) => {
          const val = e.target.value;
          column.setFilterValue((old = []) => [val ? val : undefined, old[1]]);
        }}
      />
      <Typography variant="body2">to</Typography>
      <input
        value={columnFilterValue?.[1] || ""}
        type="date"
        style={{
          outline: "none",
          padding: "3px 0",
          fontFamily: "Poppins,sans-serif",
          width: 120
        }}
        onChange={(e) => {
          const val = e.target.value;
          column.setFilterValue((old = []) => [old[0], val ? val : undefined]);
        }}
      />
    </Stack>
  ) : filterVariant === "multi-select" ? (
    <Select
      multiple
      displayEmpty
      sx={{
        "& .MuiInputBase-input": {
          padding: "3.5px 12px 5.5px",
          width: 240
        },
      }}
      onChange={(e) => {
        const value = e.target.value;
        column.setFilterValue(
          typeof value === "string" ? value.split(",") : value,
        );
      }}
      value={columnFilterValue || []}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>Select...</em>;
        }

        return selected.join(", ");
      }}
    >
      <MenuItem disabled value="">
        <em>Select...</em>
      </MenuItem>
      {sortedUniqueValues.map((value) => (
        <MenuItem value={value} key={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <React.Fragment>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        inputProps={{
          list: column.id + "list",
        }}
        sx={{ width: 240 }}
      />
    </React.Fragment>
  );
}

export default function DataTable(props) {
  const {
    data = [],
    setData,
    columnDefinitions = [],
    actionBar = () => null,
    state = {},
    tableStyle = {},
    id = Math.random(),
  } = props;
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [settingAnchorEl, setSettingAnchorEl] = React.useState(null);
  const [columnVisibility, setColumnVisibility] = React.useState(() => {
    // Load visibility state from local storage if available
    const savedVisibility = localStorage.getItem(`columnVisibility_${id}`);
    return savedVisibility ? JSON.parse(savedVisibility) : {};
  });
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  React.useEffect(() => {
    localStorage.setItem(
      `columnVisibility_${id}`,
      JSON.stringify(columnVisibility),
    );
  }, [columnVisibility, id]);

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    ...columnDefinitions,
  ];

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      inDateRange: (row, columnId, filterValue) => {
        if (filterValue?.[0] && filterValue?.[1]) {
          const from = new Date(filterValue[0]);
          const to = new Date(filterValue[1]);
          const rowDate = new Date(row.getValue(columnId)?.slice(0, 10));
          return from <= rowDate && rowDate <= to;
        } else {
          row;
        }
      },
    },
    state: {
      sorting,
      rowSelection,
      columnFilters,
      ...state,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    meta: {
      updateData: (rowIndex, newRow) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return newRow;
            }
            return row;
          }),
        );
      },
    },
  });

  useEffect(() => {
    table.setPageSize(50);
  }, []);

  const toggleSort = (columnIdx, type) => {
    const column = table.getHeaderGroups()?.[0]?.headers?.[columnIdx]?.column;

    if (column.getCanSort()) {
      column.getIsSorted() === type
        ? column.clearSorting()
        : column.toggleSorting(type === "desc");
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        height="100%"
        direction="column"
        wrap="no-wrap"
      >
        <Grid item container xs="auto">
          <Grid item xs>
            {actionBar(table)}
          </Grid>
          <Grid item xs="auto">
            {anchorEl && (
              <Popover
                id="table-filter"
                open
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onClick={(e) => e?.stopPropagation()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                disableEnforceFocus={true}
              >
                <Stack
                  p={2}
                  spacing={1}
                >
                  <MenuItem
                    sx={{ justifyContent: "space-between" }}
                    onClick={() => toggleSort(anchorEl?.dataset?.colNo, "asc")}
                  >
                    Sort A to Z
                    {table
                      .getHeaderGroups()?.[0]
                      ?.headers?.[
                      anchorEl?.dataset?.colNo
                    ]?.column.getIsSorted() === "asc" ? (
                      <DoneIcon />
                    ) : (
                      ""
                    )}
                  </MenuItem>
                  <MenuItem
                    sx={{ justifyContent: "space-between" }}
                    onClick={() => toggleSort(anchorEl?.dataset?.colNo, "desc")}
                  >
                    Sort Z to A
                    {table
                      .getHeaderGroups()?.[0]
                      ?.headers?.[
                      anchorEl?.dataset?.colNo
                    ]?.column.getIsSorted() === "desc" ? (
                      <DoneIcon />
                    ) : (
                      ""
                    )}
                  </MenuItem>
                  <Divider />
                  <Filter
                    column={
                      table.getHeaderGroups()?.[0]?.headers?.[
                        anchorEl?.dataset?.colNo
                      ]?.column
                    }
                  />
                </Stack>
              </Popover>
            )}
            <Stack direction="row" spacing={0}>
              <Tooltip title="Show/Hide Columns">
                <IconButton>
                  <ViewColumnIcon
                    onClick={(e) => {
                      setSettingAnchorEl(e.currentTarget);
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Popover
                id="table-columns-setting"
                open={Boolean(settingAnchorEl)}
                anchorEl={settingAnchorEl}
                onClose={() => setSettingAnchorEl(null)}
                onClick={(e) => e?.stopPropagation()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Stack p={2} spacing={1}>
                  {table
                    .getAllLeafColumns()
                    .slice(1)
                    .map((column) => (
                      <FormControlLabel
                        key={column.id}
                        control={
                          <Switch
                            size="small"
                            checked={column.getIsVisible()}
                            onChange={() => {
                              column.toggleVisibility();
                            }}
                          />
                        }
                        label={column.columnDef.header()}
                      />
                    ))}
                </Stack>
              </Popover>
              <TablePagination
                component="div"
                count={table?.getRowCount()}
                page={table?.getState()?.pagination?.pageIndex || 0}
                rowsPerPage={50}
                rowsPerPageOptions={[]}
                onPageChange={(e, pageNumber) => table.setPageIndex(pageNumber)}
                sx={{
                  ".MuiTablePagination-toolbar": { minHeight: "0 !important" },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs>
          {data.length ? (
            <TableContainer
              sx={{ height: "calc(100dvh - 143px)", ...tableStyle }}
            >
              <Table
                id="data-table"
                sx={{ width: "max-content", minWidth: "100%" }}
              >
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: isDarkMode ? "#3c3c3c" : "#fff",
                    zIndex: 9,
                    boxShadow: "0 1px 1px rgba(0,0,0,.03)",
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header, colNo) => (
                        <TableCell key={header.id} sx={{ opacity: 1 }}>
                          <Typography variant="h6">
                            {header.isPlaceholder ? null : (
                              <Stack direction="row" alignItems="center">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}

                                {header.column.getCanFilter() ? (
                                  <Badge
                                    color="warning"
                                    variant="dot"
                                    invisible={!header.column.getIsFiltered()}
                                  >
                                    <FilterListIcon
                                      sx={{
                                        ml: 0.5,
                                        cursor: "pointer",
                                        color: "grey.600",
                                      }}
                                      data-colNo={colNo}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorEl(e.currentTarget);
                                      }}
                                    />
                                  </Badge>
                                ) : null}

                                {{
                                  asc: (
                                    <ArrowUpwardOutlinedIcon
                                      fontSize="small"
                                      sx={{ opacity: 0.8 }}
                                    />
                                  ),
                                  desc: (
                                    <ArrowDownwardOutlinedIcon
                                      fontSize="small"
                                      sx={{ opacity: 0.8 }}
                                    />
                                  ),
                                }[header.column.getIsSorted()] ?? null}
                              </Stack>
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} sx={{ opacity: 1 }}>
                          <Typography variant="body1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoDataScreen />
          )}
        </Grid>
      </Grid>
    </>
  );
}
