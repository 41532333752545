import React from "react";
import ReactDropzone from "react-dropzone";

const Dropzone = (props) => {
  return (
    <ReactDropzone {...props} ref={props?.dropzoneRef}>
      {({ getRootProps, getInputProps }) => (
        <section style={{ width: "fit-content" }}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {props.children}
          </div>
        </section>
      )}
    </ReactDropzone>
  );
};

export default Dropzone;
