import React from "react";
import { Button, TextField, Stack, Autocomplete } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { useAxios } from "hooks/useAxios";

const AddOrEditDepartmentForm = (props) => {
    const { user } = useAuth();
    // const [departments] = useAxios('departments');
    // const departmentsArray = departments?.response?.departments.filter(d => !(props?.initialValue?.departmentName === d.departmentName)) || [];
    const [data] = useAxios(`user?companyId=${user.companyId}`);
    // const ids = new Set(departmentsArray.flatMap(dept => [...(dept.userIds || []), ...(dept.adminIds || [])]));
    // const users = data?.response?.filter(user => !ids.has(user.uid)) || [];
    const users = data?.response || [];
    const { initialValue } = props;
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [formData, setFormData] = React.useState({
        departmentName: initialValue?.departmentName || null,
        userIds: initialValue?.userIds || null,
        adminIds: initialValue?.adminIds || null
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await props?.onSubmit(formData);
        setIsSubmitting(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2} mt={0.5}>
                <TextField
                    required
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={formData.departmentName}
                    onChange={(e) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            departmentName: e.target.value,
                        }))
                    }
                />
                <Autocomplete
                    multiple
                    id="select-users"
                    options={users}
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    value={users.filter(u => formData?.userIds?.includes(u.uid))}
                    filterSelectedOptions
                    onChange={(e, newValue) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            userIds: newValue.map(u => u.uid),
                        }))
                    }
                    renderInput={(params) => (
                        <TextField
                            // required
                            {...params}
                            label="Users"
                            placeholder="Select Users"
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    id="select-admin-users"
                    options={users}
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    value={users.filter(u => formData?.adminIds?.includes(u.uid))}
                    filterSelectedOptions
                    onChange={(e, newValue) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            adminIds: newValue.map(u => u.uid),
                        }))
                    }
                    renderInput={(params) => (
                        <TextField
                            // required
                            {...params}
                            label="Admin Users"
                            placeholder="Select Users"
                        />
                    )}
                />
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button onClick={props.handleClose}>Cancel</Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Adding..." : initialValue ? "Update Department" : "Add Department"}
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default AddOrEditDepartmentForm;