import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Divider,
  Typography,
  Stack,
  Button,
  Tooltip,
  Chip,
  Skeleton,
  Link,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams, useSearchParams } from "react-router-dom";
import { customAxios, useAxios } from "hooks/useAxios";
import SocialShare from "./SocialShare";
import moment from "moment";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { ReactComponent as LinkedinIcon } from "assets/images/linkedin.svg";
import Helpers from "utils/commonHelpers";
import PrivateCredentialIcon from "components/common/PrivateCredentialIcon";
import VerifyCredential from "./VerifyCredential";
import useResponsive from "hooks/useResponsive";
import PrivateCredentialLogin from "./PrivateCredentialLogin";
import CredentialNotFound from "./CredentialNotFound";
import CustomDialog from "components/common/CustomDialog";
import JsonViewer from "components/common/JsonViewer";
import CredentialRevoked from "./CredentialRevoked";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditDocumentForm from "../CredentialsTable/EditDocumentForm";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function Preview(props) {
  const { isDesktop } = useResponsive();
  const docInfoContainerRef = useRef(null);
  let displayId;
  if (props.displayId) {
    displayId = props.displayId;
  } else {
    const params = useParams();
    displayId = params.displayId;
  }
  const [searchParams] = useSearchParams();
  const [userData] = useAxios("getProfile", { disableErrorHandling: true });
  const user = userData?.response;
  let src;
  if (props.isCredulocker) {
    src = "credulocker";
  } else if (searchParams.get("trackingCode")) {
    src = "email";
  } else if (searchParams.get("src") === "qr") {
    src = "qr";
  }
  const [data, isLoading, , reloadDoc, status] = useAxios(
    `signedDoc/${displayId}${src ? `?src=${src}` : ""}`,
    {
      attachCredulockerToken: props.displayId ? true : false,
      disableErrorHandling: true,
    },
  );
  const doc = data?.value;
  const title = doc?.metaData?.["Recipient Name"] || doc?.fileName;
  const envPrefix = doc?.visibility === "PRIVATE" ? "private-" : "";

  const { envSuffix } = Helpers.getEnvironmentDetails();

  const file = `https://${envPrefix}cdn${envSuffix}.itscredible.com/${
    doc?.documentS3Key || `issuer-data${envSuffix}/${doc?._id}.pdf`
  }`;
  const thumbnail = `https://${envPrefix}cdn${envSuffix}.itscredible.com/${
    doc?.thumbnailS3Key || `issuer-data${envSuffix}/${doc?._id}.jpeg`
  }`;
  const [pageCount, setPageCount] = useState();

  let editPermission = false;
  if (user) {
    if (user.uid === doc?.signer?.companyUserId) {
      editPermission = true;
    } else if (
      user.companyId === doc?.signer?.companyId &&
      user.role === "VM_COMPANY_ADMIN"
    ) {
      editPermission = true;
    }
  }

  const metaData = [
    {
      label: "Doc Info",
      value: doc?.docInfo?.text && (
        <div
          ref={docInfoContainerRef}
          dangerouslySetInnerHTML={{ __html: doc?.docInfo?.text }}
        ></div>
      ),
    },
    {
      label: "Recipient Name",
      value: doc?.metaData?.["Recipient Name"],
    },
    {
      label: "Issued By",
      value: doc?.company?.websites?.[0],
    },
    {
      label: "Created By",
      value: doc?.createdBy?.name,
    },
    {
      label: "Issued Via",
      value:
        doc?.source?.name && doc?.source?.website ? (
          <a href={doc?.source?.website} target="_blank" rel="noreferrer">
            {doc?.source?.name}
          </a>
        ) : doc?.source?.name ? (
          doc.source.name
        ) : null,
    },
    {
      label: "Issue Date",
      value:
        doc?.signer?.signedOn &&
        moment(doc.signer.signedOn).format("DD-MM-YYYY"),
    },
    {
      label: "Expiry Date",
      value:
        doc?.docInfo?.expiryDate &&
        moment(doc.docInfo.expiryDate).format("DD-MM-YYYY"),
    },
    {
      label: "Reference Number",
      value: doc?.displayId,
    },
    {
      label: "Skills",
      value:
        doc?.skills?.length &&
        doc.skills.map((skill) => (
          <Chip key={skill} label={skill} sx={{ mt: 1.5, mr: 1.5 }} />
        )),
    },
    {
      label: "Blockchain ID",
      value: doc?.nftToken && doc?.nftLink && (
        <>
          {doc?.nftToken} -{" "}
          <a href={doc?.nftLink} target="_blank" rel="noreferrer">
            View Details
          </a>
        </>
      ),
    },
  ];

  useEffect(() => {
    const anchors = document.querySelectorAll("#doc-info-container a");
    anchors.forEach((anchor) => {
      let href = anchor.getAttribute("href");
      if (href && !href.startsWith("http")) {
        anchor.setAttribute("href", "https://" + href);
      }
    });
  }, [doc?.docInfo?.text]);

  useEffect(() => {
    const handleClick = async (event) => {
      const path = event.composedPath();
      const linkElement = path.find((el) => el.tagName === "A");
      if (linkElement && docInfoContainerRef.current?.contains(linkElement)) {
        await customAxios.patch(`/docs/analytics/${displayId}`, {
          update: {
            $inc: { "analytics.ctr": 1 },
          },
        });
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const getDocUrl = () => {
    let url;

    if (props.isCredulocker) {
      if (doc?.company?.config?.urlForQr) {
        url = `${doc?.company?.config?.urlForQr}${props.displayId}`;
      } else {
        url = `https://portal.itscredible.com/qr/${props.displayId}`;
      }
    } else {
      const currentUrl = new URL(window.location.href);
      const params = new URLSearchParams(currentUrl.search);
      const docid = params.get("docid");

      if (docid) {
        currentUrl.search = `?docid=${docid}`;
      } else {
        currentUrl.search = "";
      }

      url = currentUrl.toString();
    }
    return url;
  };

  const handleAddToLinkedInProfile = () => {
    customAxios.patch(`/docs/analytics/${displayId}`, {
      update: {
        $set: {
          "analytics.addedToLinkedinProfileOn": new Date().toISOString(),
        },
      },
    });

    const issueDate = doc?.signer?.signedOn
      ? new Date(doc?.signer?.signedOn)
      : null;
    const expiryDate = doc?.docInfo?.expiryDate
      ? new Date(doc?.docInfo?.expiryDate)
      : null;
    let orgIdentity;
    if (doc?.company?.config?.linkedInId) {
      orgIdentity = `organizationId=${doc?.company?.config?.linkedInId}`;
    } else {
      orgIdentity = `organizationName=${doc?.docInfo?.issuer?.websites?.[0]}`;
    }
    const linkedInProfileUrl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
      doc?.fileName
    }&${orgIdentity}&issueYear=${issueDate?.getFullYear()}&issueMonth=${
      issueDate?.getMonth() + 1
    }&expirationYear=${expiryDate?.getFullYear()}&expirationMonth=${
      expiryDate?.getMonth() + 1
    }&certUrl=${encodeURIComponent(getDocUrl())}&certId=${displayId}`;

    window.open(linkedInProfileUrl, "_blank");
  };

  const handleCopyUrl = () => {
    Helpers.copy(getDocUrl());
  };

  return status === 401 ? (
    <PrivateCredentialLogin showLogin={true} />
  ) : status === 403 ? (
    <PrivateCredentialLogin showLogin={false} />
  ) : status === 404 ? (
    <CredentialNotFound />
  ) : status === 423 ? (
    <CredentialRevoked revokeMessage={data?.error?.revokeMessage} />
  ) : (
    <Grid container sx={{ flex: 1 }}>
      <Grid item xs={12} component={Paper} square={true}>
        <Box p={2}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="30%" height="21px" />
          ) : title ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              {doc?.visibility === "PRIVATE" && <PrivateCredentialIcon />}
              <Typography variant="h4">{title}</Typography>
            </Stack>
          ) : (
            <Typography variant="h4">Oops!</Typography>
          )}
        </Box>
        <Divider />
      </Grid>

      <Grid
        item
        md={8}
        xs={12}
        sx={{ backgroundColor: "#f5f5f5", position: "relative" }}
      >
        {pageCount === 1 && !isDesktop ? (
          <img
            src={thumbnail}
            style={{
              ...(isDesktop
                ? { height: "calc(100dvh - 56px)", overflow: "auto" }
                : { height: "100%" }),
              width: "100%",
              objectFit: "contain",
            }}
          />
        ) : (
          <Box
            p={isDesktop ? 4 : 2}
            sx={{
              ...(isDesktop
                ? { height: "calc(100dvh - 56px)", overflow: "auto" }
                : { height: "100%" }),
              width: "100%",
              position: "relative",
              "& *": {
                height: "100% !important",
                width: "100% !important",
                backgroundColor: "transparent !important",
              },
              "& div:has(> .columns)": {
                display: "none",
              },
              "& canvas": {
                height: "100% !important",
                width: "100% !important",
                objectFit: "contain",
                background: "transparent",
                filter:
                  "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.025)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1))",
              },
            }}
          >
            {isLoading ? (
              <Skeleton variant="rectangular" height="calc(100dvh - 120px)" />
            ) : (
              <Document
                file={file}
                onLoadSuccess={(pdfPages) => setPageCount(pdfPages.numPages)}
                loading={
                  <Skeleton
                    variant="rectangular"
                    height="calc(100dvh - 112px)"
                  />
                }
              >
                {Array.apply(null, Array(pageCount)).map((page, i) => {
                  return (
                    <Page
                      key={i + 1}
                      pageNumber={i + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  );
                })}
              </Document>
            )}
          </Box>
        )}
      </Grid>
      <Grid item md={4} xs={12} component={Paper} square={true}>
        <Box
          p={2}
          sx={
            isDesktop ? { height: "calc(100dvh - 56px)", overflow: "auto" } : {}
          }
        >
          <Stack spacing={2}>
            <Box>
              {searchParams.get("trackingCode") || props?.showAddToProfile ? (
                isLoading ? (
                  <Box mr={1} display="inline-block">
                    <Skeleton
                      variant="rectangular"
                      width="156px"
                      height="36px"
                    />
                  </Box>
                ) : (
                  doc?.visibility !== "PRIVATE" && (
                    <Box mr={1} display="inline-block">
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<LinkedinIcon style={{ fill: "#fff" }} />}
                        onClick={handleAddToLinkedInProfile}
                      >
                        Add To Profile
                      </Button>
                    </Box>
                  )
                )
              ) : null}
              <Box mr={1} display="inline-block">
                {isLoading ? (
                  <Skeleton variant="rectangular" width="120px" height="36px" />
                ) : (
                  <Button
                    disableElevation
                    variant="outlined"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={async () =>
                      await Helpers.downloader({
                        fileUrl: file,
                        fileName: `${title}.pdf`,
                      })
                    }
                  >
                    Download
                  </Button>
                )}
              </Box>
              <Box mr={1} display="inline-block">
                {isLoading ? (
                  <Skeleton variant="rectangular" width="38px" height="36px" />
                ) : (
                  <Tooltip title="Copy URL">
                    <Button
                      disableElevation
                      variant="outlined"
                      sx={{ minWidth: 0, padding: "7px 8px" }}
                      onClick={handleCopyUrl}
                    >
                      <ContentCopyOutlinedIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                )}
              </Box>
              {!isLoading && editPermission && (
                <Box display="inline-block">
                  <CustomDialog
                    AnchorComponent={(anchorProps) => (
                      <Tooltip title="Edit Credential">
                        <Button
                          {...anchorProps}
                          disableElevation
                          variant="outlined"
                          sx={{ minWidth: 0, padding: "7px 8px" }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                    )}
                    title="Edit Document"
                    Content={(dialogProps) => (
                      <EditDocumentForm
                        {...dialogProps}
                        docIds={[doc?._id]}
                        initialValues={{
                          expiryDate: doc?.docInfo?.expiryDate,
                          text: doc?.docInfo?.text,
                          skills: doc?.skills,
                        }}
                        callback={reloadDoc}
                      />
                    )}
                    closeOnOutsideClick={false}
                  />
                </Box>
              )}
            </Box>
            <SocialShare
              isLoading={isLoading}
              displayId={displayId}
              userId={doc?.signer?.companyUserId}
            />
            {!isLoading && !doc?.docInfo && (
              <Alert severity="warning">
                <AlertTitle>External Document Disclaimer!</AlertTitle>
                This credential was uploaded by the reciever and is not verified
                by Credulocker. Use caution when relying on its contents.
              </Alert>
            )}
            {metaData?.map((field) =>
              isLoading || field?.value ? (
                <Stack key={field.label}>
                  <Typography variant="caption" sx={{ opacity: 0.7 }}>
                    {field.label}
                  </Typography>
                  {isLoading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <Typography variant="body1">{field.value}</Typography>
                  )}
                </Stack>
              ) : null,
            )}
            {doc?.openBadgeJsonLd && (
              <Stack>
                <Typography variant="caption" sx={{ opacity: 0.7 }}>
                  Open Badge
                </Typography>
                <CustomDialog
                  title="Open Badge JSON-LD"
                  AnchorComponent={(props) => (
                    <Typography variant="body1" {...props}>
                      <Link sx={{ cursor: "pointer" }}>View Details</Link>
                    </Typography>
                  )}
                  Content={() => (
                    <Box
                      display="flex"
                      sx={{ "& pre": { background: "none !important" } }}
                    >
                      <JsonViewer jsonData={doc.openBadgeJsonLd} />
                    </Box>
                  )}
                />
              </Stack>
            )}
            {!props?.hideVerifyButton && (
              <VerifyCredential
                isWhiteLabel={props?.isWhiteLabel}
                {...{
                  issuerName: doc?.docInfo?.issuer?.websites?.[0],
                  isIssuerVerified:
                    doc?.docInfo?.issuer?.VMApprovalStatus === "ONBOARDED",
                  recipientName: doc?.sharedWith?.name,
                  issueDate:
                    doc?.signer?.signedOn &&
                    moment(doc.signer.signedOn).format("DD-MM-YYYY"),
                  expiryDate:
                    doc?.docInfo?.expiryDate &&
                    moment(doc.docInfo.expiryDate).format("DD-MM-YYYY"),
                }}
              />
            )}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
