import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Hex from "crypto-js/enc-hex";
import { Typography, Divider, Paper, Stack } from "@mui/material";
import { customAxios } from "hooks/useAxios";
import ResultComponent from "./ResultComponent";
import Loading from "./Loading";
import MyDropZone from "./MyDropZone";
import ReferenceNumberForm from "./ReferenceNumberForm";
import MySwal from "utils/Swal";
import { ReactComponent as ItsCredible_Logo } from "../../assets/images/logo.svg";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const container = document.querySelector("#vmVerificationWidget");
const widgetKey = container?.getAttribute("data-widget-key");
let branding = container?.getAttribute("data-widget-branding");

const VerificationWidget = () => {
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);
  const [enteredRefNumb, setEnteredRefNumb] = useState("");
  const [resultScreen, setResultScreen] = useState(false);
  const [response, setResponse] = useState({});

  const showBranding = branding !== "false";

  const closeResult = () => {
    setFiles();
    setEnteredRefNumb("");
    setResultScreen(false);
  };

  const fetchData = async (data) => {
    setLoading(true);
    const response = await customAxios.post("validateDocument", data, {
      disableErrorHandling: true,
    });
    if (response) {
      setResponse(response?.data);
      setResultScreen(true);
    }
    setLoading(false);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      MySwal.fire({
        title: "Invalid File!",
        text: "Multiple uploads are not allowed. Please upload one file at a time.",
        icon: "warning",
      });
      return;
    }
    const file = acceptedFiles[0];
    const fileType = file.name.split(".").pop().toLowerCase();
    if (!["pdf", "jpg", "jpeg"].includes(fileType)) {
      MySwal.fire({
        title: "Invalid File!",
        text: "Invalid file format. Please upload a PDF or image file.",
        icon: "warning",
      });
      return;
    }
    setFiles(file.name);
    setLoading(true);
    getBase64(file).then((data) => {
      const bdata = data.split(",")[1];
      const md5 = CryptoJS.MD5(bdata).toString(Hex);
      fetchData({ widgetKey, docHash: md5 });
    });
  };

  const handleRefNumberSubmit = () => {
    if (enteredRefNumb) {
      fetchData({ widgetKey, displayId: enteredRefNumb.trim() });
    }
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 3,
        borderColor: "primary.mainBorder",
        backgroundColor: "primary.mainSurface",
      }}
    >
      <Stack spacing={3}>
        <Typography color="text.main" variant="body1">
          Verify documents associated with us, in no time!
        </Typography>

        {resultScreen ? (
          <ResultComponent
            showBranding={showBranding}
            response={response}
            closeResult={closeResult}
            myFileName={files}
          />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <MyDropZone handleDrop={handleDrop} />

            <Stack direction="row" spacing={2} my={2} alignItems="center">
              <Divider sx={{ flex: 1 }} />
              <Typography color="text.main">OR</Typography>
              <Divider sx={{ flex: 1 }} />
            </Stack>

            <ReferenceNumberForm
              handleRefNumberSubmit={handleRefNumberSubmit}
              setEnteredRefNumb={setEnteredRefNumb}
              enteredRefNumb={enteredRefNumb}
            />
          </>
        )}
        {showBranding && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography
              alignSelf="end"
              color="text.main"
              fontSize={10}
              variant="body2"
            >
              Powered by
            </Typography>
            <ItsCredible_Logo lazy="true" width={100} height={24} />
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default VerificationWidget;
