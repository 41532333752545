import React from "react";
import {
  Alert,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "components/Table";
import { customAxios } from "hooks/useAxios.js";
import TableSkeleton from "components/common/TableSkeleton";
import ActionBar from "components/CredentialsTable/ActionBar";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import EditableEmailCell from "./EditableEmailCell";
import EditableWhatsappCell from "./EditableWhatsappCell";
import PrivateCredentialIcon from "components/common/PrivateCredentialIcon";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CustomPopover from "components/common/customPopover";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAuth } from "hooks/useAuth";

const columnHelper = createColumnHelper();

export default function CredentialTable(props) {
  const { filter, searchText, isSeeAll, setIsSeeAll } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [docs, setDocs] = React.useState([]);

  const { user } = useAuth();

  const isCompanyAdmin = user?.role === "VM_COMPANY_ADMIN";
  const currentDepartment = user?.departments?.find(
    (department) =>{ 
      return department?._id === isSeeAll
    },
  )
  const isDepartmentAdmin = currentDepartment?.adminIds?.includes(user?.uid);

  const columnDefinitions = [
    columnHelper.accessor((row) => row?.sharedWith?.name || row.fileName, {
      id: "name",
      header: () => "Recipient Name",
      cell: (info) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ width: "200px" }}
        >
          {info?.row?.original?.visibility === "PRIVATE" && (
            <PrivateCredentialIcon />
          )}
          {info?.row?.original?.isRevoked && (
            <Tooltip
              title={`Credential access is revoked for the recipient. Message: ${info?.row?.original?.revokeMessage}`}
            >
              <VisibilityOffOutlinedIcon
                fontSize="small"
                sx={{ opacity: 0.7 }}
              />
            </Tooltip>
          )}
          <Typography variant="h5" noWrap>
            <Link
              href={`${process.env.REACT_APP_BASE_ROUTE}/qr/${info?.row?.original?.displayId}`}
              target="_blank"
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              {info.getValue()}
            </Link>
          </Typography>
        </Stack>
      ),
    }),
    ...(filter !== "OUTBOX"
      ? [
          columnHelper.accessor((row) => row?.status, {
            id: "notification",
            enableColumnFilter: false,
            header: () => "Notification",
            cell: (info) => {
              const STATUS = {
                IN_PROGRESS: "INPROGRESS",
                FAILED: "FAILED",
                SUCCESS: "SUCCESS",
              };

              const ICONS = {
                WARNING: HourglassEmptyIcon,
                ERROR: CloseIcon,
                SUCCESS: DoneIcon,
                NOT_NOTIFIED: WarningAmberIcon,
              };

              const getContentItem = (text, status, error = null) => {
                const colorMap = {
                  [STATUS.IN_PROGRESS]: "warning.main",
                  [STATUS.FAILED]: "error.main",
                  [STATUS.SUCCESS]: "success.main",
                };

                const iconMap = {
                  [STATUS.IN_PROGRESS]: ICONS.WARNING,
                  [STATUS.FAILED]: ICONS.ERROR,
                  [STATUS.SUCCESS]: ICONS.SUCCESS,
                };

                return {
                  text,
                  color: colorMap[status] || "warning.main",
                  icon: iconMap[status] || ICONS.NOT_NOTIFIED,
                  ...(status === STATUS.FAILED ? { error } : {}),
                };
              };

              const content = [];
              const value = info.getValue();
              const original = info.row?.original || {};
              const timeline = original.timeline || {};
              const notify = timeline.notify || {};
              const sharedWith = original.sharedWith || {};

              const handleNotification = (type, statusData) => {
                if (statusData?.status) {
                  const { status, error } = statusData;
                  content.push(getContentItem(type, status, error));
                }
              };

              // Handle "BLOCKCHAINED" state
              if (value === "BLOCKCHAINED") {
                handleNotification("Email", notify.lastEmail);
                handleNotification("WhatsApp", notify.lastWhatsapp);

                if (content.length === 0) {
                  content.push({
                    text: "Not Notified",
                    color: "warning.main",
                    icon: ICONS.NOT_NOTIFIED,
                  });
                }
              }

              // Handle "NOTIFIED" state
              if (value === "NOTIFIED") {
                const emailStatus = notify.lastEmail?.status;
                const whatsappStatus = notify.lastWhatsapp?.status;

                if (!emailStatus && !whatsappStatus) {
                  if (sharedWith.email) {
                    content.push(getContentItem("Email", STATUS.SUCCESS));
                  }
                  if (sharedWith.whatsappNumber) {
                    content.push(getContentItem("WhatsApp", STATUS.SUCCESS));
                  }
                } else {
                  if (emailStatus) {
                    const { status, error } = notify.lastEmail;
                    content.push(getContentItem("Email", status, error));
                  }
                  if (whatsappStatus) {
                    const { status, error } = notify.lastWhatsapp;
                    content.push(getContentItem("WhatsApp", status, error));
                  }
                }
              }

              // Render
              return (
                <Stack direction="row" spacing={2}>
                  {content.map(({ text, color, icon: Icon, error }) => (
                    <Stack
                      key={text}
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <Icon fontSize="small" sx={{ color }} />
                      <Typography
                        variant="caption"
                        color={color}
                        sx={{ fontWeight: 500 }}
                      >
                        {text}
                      </Typography>
                      {error ? (
                        <CustomPopover
                          AnchorComponent={(syntax) => (
                            <Tooltip title="Error Detail">
                              <IconButton size="small" {...syntax}>
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  sx={{ color }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          PopoverComponent={(syntax) => (
                            <Stack width="400px" {...syntax} p={2}>
                              <Alert severity="error" variant="outlined">
                                <Typography variant="body2">{error}</Typography>
                              </Alert>
                            </Stack>
                          )}
                        />
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor((row) => row?.sharedWith?.email, {
      id: "email",
      header: () => "Email Address",
      cell: EditableEmailCell,
    }),
    columnHelper.accessor((row) => row?.sharedWith?.whatsappNumber, {
      id: "whatsapp",
      header: () => "WhatsApp Number",
      cell: EditableWhatsappCell,
    }),
    columnHelper.accessor((row) => row?.signer?.signedOn, {
      id: "issued_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Issued On",
      cell: (info) => moment(info.getValue()).format("DD-MM-YYYY"),
    }),
    columnHelper.accessor((row) => row?.analytics?.openedOn, {
      id: "opened_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Opened On",
      cell: (info) =>
        info.getValue() ? (
          moment(info.getValue()).format("DD-MM-YYYY")
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => JSON.stringify(row?.analytics || {}), {
      id: "share_count",
      enableColumnFilter: false,
      header: () => "Total Shares",
      cell: (info) => {
        const rowData = JSON.parse(info.getValue() || "{}");
        const linkedinShareCount = rowData?.share?.linkedin ?? 0;
        const whatsappShareCount = rowData?.share?.whatsapp ?? 0;
        const facebookShareCount = rowData?.share?.facebook ?? 0;
        const twitterShareCount = rowData?.share?.twitter ?? 0;
        let totalShareCount =
          linkedinShareCount +
          whatsappShareCount +
          facebookShareCount +
          twitterShareCount;
        return (
          <Tooltip
            title={`LinkedIn: ${linkedinShareCount}, WhatsApp: ${whatsappShareCount}, Facebook: ${facebookShareCount}, Twitter: ${twitterShareCount}`}
            arrow
          >
            <div style={{ textAlign: "center" }}>{totalShareCount || "0"}</div>
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor((row) => JSON.stringify(row?.analytics || {}), {
      id: "post_click_count",
      enableColumnFilter: false,
      header: () => "Total Post Clicks",
      cell: (info) => {
        const rowData = JSON.parse(info.getValue() || "{}");
        const linkedinClicks = rowData?.postClicks?.linkedin ?? 0;
        const whatsappClicks = rowData?.postClicks?.whatsapp ?? 0;
        const facebookClicks = rowData?.postClicks?.facebook ?? 0;
        const twitterClicks = rowData?.postClicks?.twitter ?? 0;
        const totalClickCount =
          linkedinClicks + whatsappClicks + facebookClicks + twitterClicks;
        return (
          <Tooltip
            title={`LinkedIn: ${linkedinClicks}, WhatsApp: ${whatsappClicks}, Facebook: ${facebookClicks}, Twitter: ${twitterClicks}`}
            arrow
          >
            <div style={{ textAlign: "center" }}>{totalClickCount || "0"}</div>
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor(
      (row) => JSON.stringify(row?.analytics?.verification || {}),
      {
        id: "verification_count",
        enableColumnFilter: false,
        header: () => "Verification Count",
        cell: (info) => {
          let rowData = JSON.parse(info.getValue());
          let totalVerificationCount =
            (rowData?.widget ?? 0) + (rowData?.qr ?? 0);
          return (
            <Tooltip
              title={`QR: ${rowData?.qr ?? 0} Widget: ${rowData?.widget ?? 0}`}
              arrow
            >
              <div style={{ textAlign: "center" }}>
                {totalVerificationCount || "0"}
              </div>
            </Tooltip>
          );
        },
      },
    ),
    columnHelper.accessor((row) => row?.analytics?.ctr, {
      id: "web_click_count",
      enableColumnFilter: false,
      header: () => "Traffic Sent To Website",
      cell: (info) => (
        <div style={{ textAlign: "center" }}>{info.getValue() || "0"}</div>
      ),
    }),
    columnHelper.accessor((row) => row?.analytics?.addedToLinkedinProfileOn, {
      id: "added_to_profile",
      enableColumnFilter: false,
      header: () => "Added To Profile",
      cell: (info) => (
        <div style={{ textAlign: "center" }}>
          {info.getValue() ? "Yes" : "No"}
        </div>
      ),
    }),
    columnHelper.accessor((row) => row?.docInfo?.expiryDate, {
      id: "expired_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Expiry Date",
      cell: (info) =>
        info.getValue() ? (
          moment(info.getValue()).format("DD-MM-YYYY")
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    ...(user?.departments?.length > 0 ? [columnHelper.accessor((row) => row?.departments, {
      id: "departments",
      header: () => "Departments",
      cell: (info) =>
        info.getValue()?.length ? (
          info
            .getValue()
            .map((department) => <Chip key={department?._id+String(Math.random())} label={department?.departmentName} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    })]:[]),
    ...(isSeeAll !== "false" && (isDepartmentAdmin || isCompanyAdmin)
      ? [
          columnHelper.accessor((row) => row?.createdBy?.email, {
            id: "issued_by",
            header: () => "Issued By",
            cell: (info) =>
              info.getValue() ?? <div style={{ textAlign: "center" }}>-</div>,
          }),
          columnHelper.accessor((row) => row?.lastNotifiedBy?.email, {
            id: "last_notified_by",
            header: () => "Last Notified By",
            cell: (info) =>
              info.getValue() ?? <div style={{ textAlign: "center" }}>-</div>,
          }),
        ]
      : []),
    columnHelper.accessor((row) => row?.docTags, {
      id: "labels",
      meta: {
        filterVariant: "multi-select",
      },
      filterFn: "arrIncludesSome",
      header: () => "Labels",
      cell: (info) =>
        info
          .getValue()
          ?.filter(
            (tag) =>
              tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
          )?.length ? (
          info
            .getValue()
            .filter(
              (tag) =>
                tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
            )
            .map((tag) => <Chip key={tag} label={tag} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => row?.skills, {
      id: "skills",
      meta: {
        filterVariant: "multi-select",
      },
      filterFn: "arrIncludesSome",
      header: () => "Skills",
      cell: (info) =>
        info.getValue()?.length ? (
          info
            .getValue()
            .map((skill) => <Chip key={skill} label={skill} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
  ];

  const fetchDocs = async () => {
    setIsLoading(true);
    let response;
    if (filter) {
      response = await customAxios.get(
        `docs/?isSeeAll=${isSeeAll}${
          filter === "SENT"
            ? `&status={"$in":["NOTIFIED","BLOCKCHAINED"]}`
            : filter === "OUTBOX"
            ? ""
            : `&tag=${encodeURIComponent(filter)}`
        }`,
      );
    } else if (searchText) {
      response = await customAxios.get(
        `docs/search?isSeeAll=${isSeeAll}&searchText=${encodeURIComponent(
          searchText,
        )}`,
      );
    }
    if (response?.data) {
      setDocs(
        filter === "OUTBOX"
          ? response.data.docs.filter((row) => row?.timeline?.notify?.outbox)
          : response.data.docs,
      );
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocs();
  }, [searchText, filter, isSeeAll]);

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          id="credentials-table"
          columnDefinitions={columnDefinitions}
          data={docs}
          setData={setDocs}
          actionBar={(table) => (
            <ActionBar
              {...{
                table,
                fetchDocs,
                isSeeAll,
                setIsSeeAll,
                showSeeAllToggle:
                  searchText ||
                  filter === "SENT" ||
                  filter === "DRAFT" ||
                  filter === "OUTBOX",
              }}
            />
          )}
        />
      )}
    </>
  );
}
